import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import img_modal from '../../assets/images/ModalInicio/ReconJefes-CartaEg-2024_Modal.png'

const ModalRJCE = ({ toggleFunction, modalState }) => {
  const titulo = 'Entrega de Carta de Egreso y Reconocimiento a los Jefes de Residentes Año 2024'

  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          <img src={img_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner mb-4' />

          <p className='heading-p'>La División de Estudios para Graduados de la Facultad de Medicina de la Universidad del Zulia, se complace en invitarle al acto de: </p>

          <div className='info-group'>
            <h3 className='modal-subtitle continuous spaced-lg lh-base'>{titulo}</h3>

            <p className='spaced-md'>Con gran entusiasmo, les extendemos una cordial invitación al acto de entrega de la Carta de Egreso a los estudiantes de los diferentes Programas de Posgrado que culminaron su escolaridad y presentaron su trabajo especial de grado y además el Reconocimiento a los Jefes de Residentes año 2024. </p>
            <p className='mb-0'>Este evento es una ocasión especial para celebrar los logros de nuestros residentes y agradecerles por su dedicación y esfuerzo a lo largo de su formación.</p>
            
          </div>

          <div className='info-group'>
            <div className='span-group'>
              <span className='fw-bold'>Fecha: </span>
              <span>04 de diciembre de 2024</span>
            </div>
            <div className='span-group'>
              <span className='fw-bold'>Hora: </span>
              <span>2:00 PM</span>
            </div>
            <div className='span-group spaced-md'>
              <span className='fw-bold'>Lugar: </span>
              <span>Facultad de Medicina Auditorio Dr. Antonio Borjas Romero</span>
            </div>
            <p className='fw-bold spaced-lg'>Asistir con bata blanca y sin acompañantes</p>

            <div className='d-flex flex-column justify-content-center '>
              <span className='fw-semibold'>Dr. Freddy Pachano Arenas</span>
              <span className='fw-semibold'>Director</span>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalRJCE }