import React from 'react'
import programas from '../../assets/data/programas_pec.json'
import { Link, useOutletContext } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap';

const ItemProgFrecuentes = () => {
  const context = useOutletContext();
  const currentPath = context[0];
  const parentRoute = context[1];
  const selectedPrograma = programas.find(programa => programa.path === currentPath)

  return (
    <>
      <Breadcrumb className='programas-breadcumb'>
        {
          selectedPrograma ? 
          (
            <>
            <Breadcrumb.Item >
              <Link to={parentRoute} className='breadcrumb-link text-break'>Programas frecuentes</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className='text-break' active>{selectedPrograma.nombre}</Breadcrumb.Item>
            </>
          ) : 
          (
            <Breadcrumb.Item className='text-break' active>Programas frecuentes</Breadcrumb.Item>
          )
        }
      </Breadcrumb>
      <div className='programa-container'>
        <h2 className='programa-title'>{selectedPrograma.nombre_full}</h2>

        <div className='span-group-container'>
          <div className='span-group'>
            <i class="bi bi-person-fill"></i>
            <span className='span-lead'>Dirigido a: </span>
            <span>{selectedPrograma.info.publico_dirigido}</span>
          </div>

          <div className='span-group'>
            <i class="bi bi-caret-right-fill"></i>
            <span className='span-lead'>Modalidad: </span>
            <span className='fw-semibold custom-pill'>{selectedPrograma.info.modalidad} </span>
          </div>

          <div className='span-group'>
            <i class="bi bi-clock-fill"></i>
            <span className='span-lead'>Duración: </span>
            <span>{selectedPrograma.info.duracion}</span>
          </div>

          <div className='span-group'>
            <i class="bi bi-calendar3"></i>
            <span className='span-lead'>Fecha de Inicio: </span>
            <span>{selectedPrograma.info.fecha_inicio}</span>
          </div>

          <div className='span-group'>
            <i class="bi bi-pencil-square"></i>
            <span className='span-lead'>Inscripciones: </span>
            <span>{selectedPrograma.info.inscripciones}</span>
          </div>

          <div className='span-group'>
            <i class="bi bi-envelope"></i>
            <span className='span-lead'>Correo: </span>
            <a className='span-link' href={'mailto:'+ selectedPrograma.info.correo}>{selectedPrograma.info.correo}</a>
          </div>

          <div className='span-group spaced'>
            <span className='span-lead-bold'>Coordinadora Académica: </span>
            <span className='span-lead'>{selectedPrograma.info.coordinador}</span>
          </div>
        </div>

        <a href={selectedPrograma.info.urlInscripcion} target='blank' className='btn btn-primary link-button'>
          Formulario de Inscripción
        </a>
      </div>
    </>
  )
}

export { ItemProgFrecuentes }