import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import dataProgramas from '../../assets/data/programas_academicos.json'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { TabDescripcion } from './Tabs_ESP/TabDescripcion';
import { TabObjetivos } from './Tabs_ESP/TabObjetivos';
import { TabPerfiles } from './Tabs_ESP/TabPerfiles';
import { TabRequisitos } from './Tabs_ESP/TabRequisitos';
import { TabReglamento } from './Tabs_ESP/TabReglamento';
import { TabPlanEstudios } from './Tabs_ESP/TabPlanEstudios';

const toolbarOptions = [
    {
        name: "Descripción",
        hashtag: "#descripcion"
    },
    {
        name: "Objetivos",
        hashtag: "#objetivos"
    },
    {
        name: "Perfiles",
        hashtag: "#perfiles"
    },
    {
        name: "Requisitos",
        hashtag: "#requisitos"
    },
    {
        name: "Reglamento",
        hashtag: "#reglamento"
    },
    {
        name: "Plan de Estudios",
        hashtag: "#plan-estudios"
    }
]

function ItemEspecialidad() {
    const params = useParams();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const selectedItem = dataProgramas.programas[2].children.find((programa) => programa.pathname === params.pathname)
    const selectedPrograma = selectedItem.programas.find((programa) => programa.id === parseInt(searchParams.get('sede')))
    
    const [selectedSede, setSede] = useState("");
    const currentHash = location.hash;

    useEffect(() => {
        if(selectedPrograma !== undefined){
            setSede(dataProgramas.programas[3].children.find((sede) => sede.id === selectedPrograma.sede).nombre);
        } else {
            setSede("")
        }
    },[selectedPrograma])

    return (
        <>
            <div className='programa-container programa-especialidad'>
            {
                selectedItem ? <h1 className='nombre-programa nombre-especialidad'>{selectedItem.nombre}</h1> : <h3>Página no encontrada</h3>
            }
            {
                selectedItem.release ? (
                    <>
                        <div className='dropdown-container d-flex'>
                            <Dropdown className='dropdown-sede'>
                                <Dropdown.Toggle className='dropdown-toggle' variant="primary" id="dropdown-basic">
                                    Sede 
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-menu'>
                                {
                                    selectedItem.programas && selectedItem.programas.map(programa => (
                                        dataProgramas.programas[3].children.map(sede => (
                                            (sede.id === programa.sede) && ( 
                                                <Dropdown.Item className='dropdown-item' 
                                                    onClick={() => {
                                                        navigate(`?sede=${programa.id}`);
                                                    }}>
                                                        <span className='dropdown-item-span'>
                                                        {sede.nombre}
                                                        </span>
                                                </Dropdown.Item>
                                            )
                                        ))
                                    ))
                                }
                                </Dropdown.Menu>
                            </Dropdown>

                            <div className='card sede-display'>
                            {
                                (selectedSede !== "")
                                ? <span className='sede-name'>{selectedSede}</span>
                                : <span className='sede-name'>Seleccione la sede...</span>
                            }
                            </div>
                        </div>
                        {
                            ((selectedSede !== "" && (selectedPrograma !== undefined))) ?
                            <section className='programa-section'>
                                <div className='toolbar-container'>
                                    <ButtonGroup className='nav-toolbar'>
                                        {
                                            toolbarOptions.map((option) => (
                                                <Button key={option.name}
                                                    className='toolbar-button'
                                                    variant="secondary"
                                                    active={
                                                        (location.hash === option.hashtag)
                                                            ? true
                                                            : ((location.hash === '' && option.hashtag === '#descripcion') ? true : false)}
                                                    onClick={() => navigate(location.pathname + location.search + option.hashtag)}>
                                                    {option.name}
                                                </Button>
                                            ))
                                        }
                                    </ButtonGroup>
                                </div>
                            {
                                selectedPrograma !== null && 

                                <div className='tab-container tab-especialidades'>
                                    { (currentHash === "#descripcion" || currentHash === "") && 
                                        <TabDescripcion programData={selectedPrograma}/>
                                    }
                                    { (currentHash === "#objetivos") && 
                                        <TabObjetivos programData={selectedPrograma}/>
                                    }
                                    { (currentHash === "#perfiles") && 
                                        <TabPerfiles programData={selectedPrograma}/>
                                    }
                                    { (currentHash === "#requisitos") && 
                                        <TabRequisitos programData={selectedPrograma}/>
                                    }
                                    { (currentHash === "#reglamento") && 
                                        <TabReglamento programData={selectedPrograma}/>
                                    }
                                    { (currentHash === "#plan-estudios") && 
                                        <TabPlanEstudios programData={selectedPrograma}/>
                                    }
                                </div>
                            }
                            </section>   
                            :  
                            <section className='programa-section'>
                                Seleccione una sede para visualizar el contenido de su programa.
                            </section>
                        }
                    </>
                ) :
                (
                    <span>En construcción...</span>
                )
            }
            </div>
        </>
    )
}

export { ItemEspecialidad }